<template>
    <div id="CourseIndex"><router-view></router-view></div>
</template>
<script>
export default {
    name: 'CourseIndex',
}
</script>
<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { updateDonateStatus } from '@/service/api'
const store = useStore()
onMounted(async () => {
    let serverRole = store.getters.getServerRole
    if (serverRole === 'user') {
        await updateDonateStatus()
    }
})
</script>
<style lang="scss" scoped></style>
